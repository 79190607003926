import { useEffect } from "react";
import AxiosInterceptorContext from "./AxiosInterceptorContext";
import axios from "axios";
import React from "react";
import {  useAuth } from "../AuthContext";
import { loaderEnd } from "../../redux/slices/loader";
import { useDispatch } from "react-redux";
import {
  buildCognitoCreds,
  cognitoCurrentUser,
} from "../../http/services/cognito.service";
import { useLocalStorage } from "../../http/services/local-storage";

export const AxiosInterceptorContextProvider = ({ children }) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { getItem, setItem, removeItem } = useLocalStorage();
  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = async (error) => {
      if (error?.response?.status === 403) {
        // error?.response?.status === 401 ||
        cognitoCurrentUser()
          .then(async (user) => {
            setItem(
              "authCognitoId",
              user.signInUserSession.getIdToken().payload.sub
            );
            const awsCred = await buildCognitoCreds(
              user.signInUserSession.getIdToken().getJwtToken()
            );
            setItem("authAwsCred", JSON.stringify(awsCred));
            window.location.reload();
          })
          .catch((err) => {
            if (err === "The user is not authenticated") {
              localStorage.clear();
              window.location.reload();
              return;
            }
          });
      }
      return Promise.reject(error);
    };
    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, []);
  return (
    <AxiosInterceptorContext.Provider value={"AXIOS"}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};

export default axios;
