import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/new-logo.png";
import yolohLogo from "../assets/images/logo.svg";
import backArrow from "../assets/images/header_back_arrow.svg";
import drawerClose from "../assets/images/drawer-close.svg";

export default function SwipeableTemporaryDrawer({
  setLoginModal,
  isLayout,
  isPrivate,
  authUser,
  open,
  setOpen,
  handleLogout,
  openLoginModal,
  loginModal,
  closeLoginModal,
  accountCreated,
  setHelpModal
}) {
  const [state, setState] = React.useState({
    right: false,
  });
  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const scrollToDiv = (id) => {
    toggleDrawer("right", false);
    const element = document.getElementById(id.replace("#", ""));
    window.scrollTo({
      left: element?.offsetLeft,
      top: element?.offsetTop,
      behavior: "smooth",
    });
  };
  const list = (anchor) => (
    <Box
      sx={{
        minWidth: anchor === "top" || anchor === "bottom" ? "auto" : "300px",

        padding: "20px",
        backgroundColor: "#09143A",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {localStorage.getItem("authUser") &&
      localStorage.getItem("authUserProfile") ? (
        <div className="mainSideBar p-0">
          <div>
            <img
              src={drawerClose}
              style={{ cursor: "pointer" }}
              onClick={() => toggleDrawer("right", false)}
            />
            <div className="sideBarUser">
              <div className="sideBarUserIcon avatarUser">{`${authUser?.authUserProfile?.first_name?.charAt(
                0
              )}${authUser?.authUserProfile?.last_name?.charAt(0)}`}</div>
              <h4 className="profileUserName cursor-pointer">
                {`Hi, ${
                  authUser?.authUserProfile?.first_name.split(" ")?.length >
                    0 &&
                  authUser?.authUserProfile?.first_name
                    .split(" ")[0]
                    ?.slice(0, 10)
                } ${authUser?.authUserProfile?.last_name?.slice(0, 1)}.`}
              </h4>
            </div>
            <div className="sideBarTopMenu">
              <span onClick={() => navigate("/overview")}>Overview</span>
              <span onClick={() => navigate("/insurances")}>
                Insurance Marketplace
              </span>
              <span onClick={() => navigate("/policy-vault")}>Vault</span>
              <span onClick={() => navigate("/insurance-verification-summary")}>
                Insurance Verification Summary
              </span>
              <span onClick={() => navigate("/profile")}>My Profile</span>
            </div>
          </div>
          <div className="sideBarBottomMenu">
            <li
              // onClick={() => {
              //   navigate("/help");
              // }}
              onClick={() => setHelpModal(true)}
            >
              <span>Help</span>
            </li>
            <li
              onClick={() => {
                navigate("/");
                handleLogout();
              }}
            >
              <span>Logout</span>
            </li>
          </div>{" "}
        </div>
      ) : (
        <>
          <img
            width="136px"
            alt="Angular Logo"
            src={yolohLogo}
            className="logoMain"
            onClick={() => navigate("/")}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
            
              {/* <Divider style={{ borderColor: "rgba(255, 255, 255, 0.5)" }} />
              <li>
                <NavLink
                  onClick={() => scrollToDiv("insurance-marketplace")}
                  to="/#insurance-marketplace"
                  style={{ color: "white" }}
                >
                  Insurance Marketplace
                </NavLink>
              </li> */}
             
              {/* <Divider style={{ borderColor: "rgba(255, 255, 255, 0.5)" }} /> */}
              <li>
                <NavLink
                  onClick={() => scrollToDiv("about")}
                  to="/#about"
                  style={{ color: "white" }}
                >
                  About
                </NavLink>
              </li>
              <Divider style={{ borderColor: "rgba(255, 255, 255, 0.5)" }} />
                <li>
                <NavLink
                  onClick={() => scrollToDiv("service")}
                  to="/#service"
                  style={{ color: "white" }}
                >
                  Services
                </NavLink>
              </li>
              <Divider style={{ borderColor: "rgba(255, 255, 255, 0.5)" }} />
              <li>
                <NavLink
                  to="/#join-us"
                  onClick={() => scrollToDiv("join-us")}
                  style={{ color: "white" }}
                >
                  Join Us
                </NavLink>
              </li>
              <Divider style={{ borderColor: "rgba(255, 255, 255, 0.5)" }} />
              <li>
                <NavLink
                  to="/#contact-us"
                  onClick={() => scrollToDiv("contact-us")}
                  style={{ color: "white" }}
                >
                  Help
                </NavLink>
              </li>
            </div>

            <button
              className="loginBtn loginBtnHeader responsiveBtnHeader"
              style={{ width: "100%", margin: "30px 0px" }}
              onClick={() => openLoginModal(!loginModal)}
            >
              Login
            </button>
          </div>
        </>
      )}
    </Box>
  );

  return (
    <div className="responsive-header">
      <div onClick={toggleDrawer("right", true)}>
        <FormatAlignJustifyIcon style={{ color: "white" }} />
      </div>

      <React.Fragment>
        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          PaperProps={{
            sx: {
              backgroundColor: "#09143A",
            },
          }}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
